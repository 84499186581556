import setOpacity from "helpers/setOpacity"

const primary = "#232427"
const accent = "#E54A30"
const black = primary
const blue = "#4850E2"
const red = "#E95353"
const grey = "#ECECEC"
const grey30 = "#A5A4A8"
const grey22 = "#222222"
const grey60 = "#606060"
const grey65 = "#656565"
const grey97 = "#979797"
const white = "white"
const gray4 = "#A5A4A8"

export default {
  white,
  primary,
  black,
  blue,
  red,
  secondaryBlack: setOpacity(0.6, black),
  grey,
  gray4,
  grey30,
  grey22,
  grey60,
  grey65,
  grey97,
  disabledBlack: setOpacity(0.4, black),
  bgBlack: setOpacity(0.06, black),
  border: setOpacity(0.2, black),
  accent,
}
