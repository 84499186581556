import "../public/fonts/NotoSansJP/style.css"
import "../public/fonts/FormaleGrotesque/style.css"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ConfigProvider } from "antd"
import { NextHead } from "components/shared/NextHead"
import { AuthProvider } from "contexts/auth"
import { useSwitchLanguage } from "hooks/shared/useSwitchLanguage"
import { NextPage } from "next"
import Head from "next/head"
import { appWithTranslation } from "next-i18next"
import { useEffect } from "react"
import { SWRConfig } from "swr"
import { BugsnagClient } from "utils/bugsnagClient"

require("styles/general.less")

const stripeKeys = {
  development:
    "pk_test_51M6qdJE3BrQvIGc9V1nF1SsosMspn1YMyY3U7Z95KGIGQCP4SszwULMWXOYeg3TGWJngZbfHUGbib7xp4AQZkW0D00sVhWZiM5",
  staging:
    "pk_test_51M6qdJE3BrQvIGc9V1nF1SsosMspn1YMyY3U7Z95KGIGQCP4SszwULMWXOYeg3TGWJngZbfHUGbib7xp4AQZkW0D00sVhWZiM5",
  production:
    "pk_live_51M6qdJE3BrQvIGc9fvebtoIorUtBzIsOWxzdcSh52I1uAgXM7iPO5kRjsOre1ZOG0X0rprZOQLQKhz73ujtMbjcX00Ufhl4gwM",
}

const envString = process.env.NEXT_PUBLIC_NODE_ENV || "development"
const stripePromise = loadStripe(
  stripeKeys[envString as "development" | "staging" | "production"]
)

export type NextPageWithHead<Props> = NextPage<Props> & { getHead?: (pageProps: Props) => JSX.Element }

function App({ Component, pageProps }: { Component: NextPageWithHead<object> ; pageProps: object}): JSX.Element {
  const { switchLanguageOnFirstAccess } = useSwitchLanguage()
  useEffect(() => {
    // NOTE: ブラウザでのみBugsnagを初期化する
    if(typeof window !== "undefined") {
      BugsnagClient.setup()
    }

    // NOTE: 初回アクセス時に言語を切り替える
    switchLanguageOnFirstAccess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* NOTE: メタデータを上書きする場合、各ページコンポーネントでgetHeadを定義する。 */}
      {Component.getHead ? Component.getHead(pageProps) : <NextHead />}
      <AuthProvider>
        <ConfigProvider autoInsertSpaceInButton={false}>
          <SWRConfig value={{
            onError: (error) => {
              // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにBugsnagに吐いておく
              BugsnagClient.notify(error)
              // ローカルでの開発時にデバックしやすいようにエラーをログに出す
              if (envString === "development") {
                console.error(error)
              }
            },
          }}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
              />
              {/* Google tag (gtag.js) */}
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-5BPJ1C4V88" />
              <script src="https://unpkg.com/@googlemaps/js-api-loader@1.x/dist/index.min.js" />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-5BPJ1C4V88', { page_path: window.location.pathname });
                `,
                }}
              />
            </Head>
            <Elements stripe={stripePromise} options={{ locale: "ja" }}>
              <Component {...pageProps} />
            </Elements>
          </SWRConfig>
        </ConfigProvider>
      </AuthProvider>
    </>
  )
}

export default appWithTranslation(App)
