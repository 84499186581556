import api from "api"
import { SignInModal, SignUpModal } from "components/pages/auth"
import {
  ComponentType,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"

type AuthenticateBeforeExecutionArgs = {
  notificationText: string,
  callback: () => void
}

const AuthContext = createContext<{
  user: User | undefined
  setUser?: (data: User) => void
  isAuthenticated: boolean
  loading: boolean
  setIsSignInOpened: (data: boolean) => void
  setIsSignUpOpened: (data: boolean) => void
  setSignInNotification: (data: string) => void
  setSignUpNotification: (data: string) => void
  authenticateBeforeExecution: ({ notificationText, callback }: AuthenticateBeforeExecutionArgs) => void
    }>({
      user: undefined,
      isAuthenticated: false,
      loading: false,
      setIsSignInOpened: () => {},
      setIsSignUpOpened: () => {},
      setSignInNotification: () => {},
      setSignUpNotification: () => {},
      authenticateBeforeExecution: () => {}
    })

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(true)
  const [isSignInOpened, setIsSignInOpened] = useState(false)
  const [isSignUpOpened, setIsSignUpOpened] = useState(false)
  const [signInNotification, setSignInNotification] = useState("")
  const [signUpNotification, setSignUpNotification] = useState("")

  useEffect(() => {
    async function getUser() {
      const { data } = await api.get("/users/me")
      if (data) {
        setUser(data)
      }
      setLoading(false)
    }
    getUser()
  }, [])

  const handleSignUpClose = () => {
    setIsSignUpOpened(false)
  }

  const handleSignUpSuccess = () => {
    handleSignUpClose()
    window.location.reload()
  }

  const handleSignUp = () => {
    setIsSignInOpened(false)
    setIsSignUpOpened(true)
  }

  const handleSignIn = () => {
    setIsSignUpOpened(false)
    setIsSignInOpened(true)
  }

  const handleSignInClose = () => {
    setIsSignInOpened(false)
    setSignInNotification("")
  }

  const handleSignInSuccess = () => {
    handleSignInClose()
    window.location.reload()
  }

  const authenticateBeforeExecution = ({ notificationText, callback }: AuthenticateBeforeExecutionArgs) => {
    if (!user) {
      setSignInNotification(notificationText)
      setIsSignInOpened(true)
      return
    }

    callback()
  }

  if (loading) return null

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        setUser,
        loading,
        setIsSignInOpened,
        setIsSignUpOpened,
        setSignInNotification,
        setSignUpNotification,
        authenticateBeforeExecution
      }}
    >

      {children}

      <SignInModal
        notification={signInNotification}
        visible={isSignInOpened}
        onCancel={handleSignInClose}
        onSuccess={handleSignInSuccess}
        onSignUp={handleSignUp}
      />
      <SignUpModal
        notification={signUpNotification}
        visible={isSignUpOpened}
        onCancel={handleSignUpClose}
        onSuccess={handleSignUpSuccess}
        onSignIn={handleSignIn}
      />
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

function ProtectRoute(Component: ComponentType<any>) {
  // eslint-disable-next-line react/display-name
  return () => {
    const { isAuthenticated, loading, setIsSignInOpened } = useAuth()

    useEffect(() => {
      if (!isAuthenticated && !loading) {
        setIsSignInOpened(true)
      }
    }, [loading, isAuthenticated, setIsSignInOpened])

    if (!isAuthenticated) {
      return null
    }

    return <Component />
  }
}

export { AuthProvider, ProtectRoute, useAuth }
