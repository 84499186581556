import { Spin } from "antd"
import { View } from "react-native"

export const Loading = () => {
  return (
    <View
      style={{
        height: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </View>
  )
}
