import Bugsnag from "@bugsnag/js"

export class BugsnagClient {
  static setup() {
    Bugsnag.start({ apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "" })
  }

  static notify(error: Error) {
    Bugsnag.notify(error)
  }

  static setUser(owner: Owner) {
    Bugsnag.setUser(
      owner.id,
      owner.email,
      owner.name,
    )
  }
}
