
import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { useTranslation } from "next-i18next"
import { FC } from "react"

export const GuestBookArtxLogo: FC = () => {
  const { t } = useTranslation()
  const { xs } = useBreakpoint()

  return (
    <div
      style={{
        background: "url('/login-bg.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "black",
        display: "flex",
        padding: xs ? "32px 24px 40px 24px" : "60px 48px",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        gap: 32,
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: xs ? "0px 0px 8px 8px" : "8px 0px 0px 8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: xs ? 12 : 16,
          alignSelf: "stretch"
        }}
      >
        <Image src={"/logo-white.svg"} height={42} width={107}/>
        <Typography.Text
          style={{
            color: "var(--AX---White, #FFF)",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "NotoSansJP",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: xs ? 700 : 400,
            lineHeight: "150%",
            letterSpacing: 0.8
          }}
        >
          {t("オールインワンのアート業界向けプラットフォーム")}
        </Typography.Text>
      </div>
      <Typography.Text
        style={{
          color: "var(--AX---White, #FFF)",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "NotoSansJP",
          fontSize: 10,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: 0.8
        }}
      >
        {t("活躍するギャラリーやアーティストに関する情報をお届けします。")}
        <br/>
        {t("また、オンラインでの作品購入、抽選会への応募、芳名帳への登録、オークションへの参加など、アートに関するさまざまな機能の一元管理が可能です。")}
      </Typography.Text>
    </div>
  )
}
