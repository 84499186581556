import { Divider, message, ModalProps, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import api from "api"
import { Button } from "components/shared/Button"
import { Loading } from "components/shared/Loading"
import { Modal } from "components/shared/Modal"
import Constants from "helpers/constants"
import Cookies from "js-cookie"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { CSSProperties, FC, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

import { GuestBookArtxLogo } from "../customers/GuestBookArtxLogo"

export type SignInValues = {
  email: string
  password: string
};

export const SignInModal: FC<ModalProps & { notification?: string, onSuccess: () => void, onSignUp: () => void }> =
  ({ visible, onCancel, onSuccess, onSignUp }) => {
    const { t } = useTranslation()
    const { xs } = useBreakpoint()

    const router = useRouter()
    const [loading, setLoading] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm<SignInValues>({ mode: "onChange" })

    const handlePasswordChange = (e: React.MouseEvent<HTMLElement>) => {
      router.push("/change_password_email")

      if (onCancel) {
        onCancel(e)
      }
    }

    const handleSignIn = async (values: SignInValues) => {
      try {
        setLoading(true)

        const { data: user, error }: { data: { token: string }; error: any } = await api.post("/sign_in", values)

        setLoading(false)

        if (user) {
          Cookies.set(Constants.cookieName, user.token, { expires: 60 })
        }

        if (error) {
          message.error(error.data.error)
        } else {
          onSuccess()
        }
      } catch (error: any) {
        message.error(error.data.error)
      }
    }

    const onSubmit = (data: SignInValues) => {
      handleSignIn(data)
    }

    const labelStyle = {
      color: "var(--Black, #232427)",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NotoSansJP",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "100%",
      letterSpacing: 0.8
    }

    const inputStyle = {
      display: "flex",
      padding: 16,
      height: 48,
      alignItems: "flex-end",
      gap: 12,
      alignSelf: "stretch",
      borderRadius: 1,
      border: "1px solid var(--AX---Gray-3, #C4C4C4)",
      background: "var(--White, #FFF)",
      "::placeholder": {
        color: "var(--AX---Gray-3, #C4C4C4)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "NotoSansJP",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "100%",
        letterSpacing: 0.8
      }
    }

    const formItemStyle: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 8,
      alignSelf: "stretch",
    }

    if (loading) return <Loading />

    return (
      <StyledModal
        footer={null}
        visible={visible}
        width={xs ? 343 : 1040}
        onCancel={onCancel}
        loading={loading}
      >
        <div
          style={{
            display: "flex",
            flexDirection: xs ? "column-reverse" : "row",
            width: xs ? 343 : 1039,
            height: xs ? "auto" : 600,
            alignItems: xs ? "center" : undefined,
            justifyContent: "center",
            scrollbarWidth: "none"
          }}
        >
          <GuestBookArtxLogo />
          <div
            style={{
              display: "flex",
              padding: xs ? "40px 16px 60px 16px" : "60px 40px",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: xs ? 8 : "0px 8px 8px 0px",
              border: "1px solid var(--AX---Gray-2, #ECECEC)",
              background: "var(--AX---White, #FFF)",
              boxShadow: "0px 2px 2px -1px rgba(61, 47, 142, 0.10), 0px 1px 1px 0px rgba(61, 47, 142, 0.04), 0px 2px 8px 0px rgba(61, 47, 142, 0.08)",
              alignSelf: xs ? "stretch" : undefined,
              width: xs ? "100%" : "50%",
              gap: 16
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignSelf: "stretch"
              }}
            >
              <Typography.Text
                style={{
                  color: "var(--Black, #232427)",
                  textAlign: "center",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "NotoSansJP",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "100%",
                  letterSpacing: 0.8,
                  flex: "1 0 0"
                }}
              >
                {t("ログイン")}
              </Typography.Text>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 32,
                  marginTop: 32
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 24,
                  }}
                >
                  <div style={formItemStyle}>
                    <label style={labelStyle}>{t("ID(メールアドレス)")}</label>
                    <input type="email" style={inputStyle} {...register("email", { required: true })} placeholder={t("IDを入力してください")} />
                    {errors.email && <p style={{ ...labelStyle, color: "red" }}>{t("メールアドレス")}{t("は必須です")}</p>}
                  </div>

                  <div style={formItemStyle}>
                    <label style={labelStyle}>{t("パスワード")}</label>
                    <input type="password" style={inputStyle} {...register("password", { required: true })} placeholder={t("パスワードを入力してください")} />
                    {errors.password && <p style={{ ...labelStyle, color: "red" }}>{t("パスワード")}{t("は必須です")}</p>}
                  </div>
                </div>

                <div>
                  <Button
                    type="primary"
                    fontWeight="normal"
                    onClick={(e) => handleSubmit(onSubmit)(e)}
                    style={{ display: "flex" }}
                  >
                    {t("ログイン")}
                  </Button>
                </div>
              </form>

              <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
                <a style={{ textDecoration: "underline", color: "#232427", textAlign: "center" }} onClick={handlePasswordChange}>
                  {t("パスワードお忘れの方はこちら")}
                </a>
              </div>
            </div>

            <Divider
              style={{
                color: "var(--Black, #232427)",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "NotoSansJP",
                fontSize: 14,
                width: 86,
                height: 14,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "200%",
                letterSpacing: 0.8,
                margin: "0 0 16px 0"
              }}
            >
              {t("または")}
            </Divider>
            <Button
              size={"lg"}
              style={{ width: "100%" }}
              type={"secondary"}
              onClick={onSignUp}
            >
              <Typography.Text
                style={{
                  color: "var(--Black, #232427)",
                  textAlign: "center",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "NotoSansJP",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "100%",
                  letterSpacing: 0.8
                }}
              >
                {t("新規会員登録")}
              </Typography.Text>
            </Button>
          </div>
        </div>
      </StyledModal>
    )
  }

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px;
    overflow: auto;
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`
